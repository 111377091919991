/* global BigInt */
import { useState } from "react";
import { useEffect } from "react";
import $ from 'jquery';
import {
  checkCorrectNetwork,
  checkWalletAvailable,
  getMainBalance,
  getUserAddress,
  promptSwitchNetwork,
} from "./actions/Web3Actions";
import {
  checkDMSApproval, checkDMSBalance, approveDMS, ticketPriceCheck, nextGameTimeCheck, getTokenName, buyTickets,
  checkDMSApproval2, approveDMS2, ticketPriceCheck2, nextGameTimeCheck2, getTokenName2, buyTickets2,
  checkDMSApproval3, approveDMS3, ticketPriceCheck3, nextGameTimeCheck3, getTokenName3, buyTickets3,
  checkRewardTokenDistribute, checkRewardTokenDistribute2, checkRewardTokenDistribute3, checkRewardTokenBurned,
  checkRewardTokenBurned2, checkRewardTokenBurned3,
  getDistributorBalance, getDistributorBalance2, getDistributorBalance3,
  ticketNumberCheck, ticketNumberCheck2, ticketNumberCheck3,
  oldWinnerDetails,
  oldWinnerDetails2,
  oldWinnerDetails3,
  ownerTicketCountCheck, ownerTicketCountCheck2, ownerTicketCountCheck3
} from "./actions/SmartActions";
import "./App.css";

function truncateAddress(address, startLength, endLength) {
  if (address.length <= startLength + endLength) {
    return address;
  }

  const start = address.slice(0, startLength);
  const end = address.slice(-endLength);
  return `${start}...${end}`;
}
function App() {

  const [data, setData] = useState({
    wallet: false,
    chainId: "Unavailable",
    address: "Unavailable",
    balance: 0,
    DMSAllowance: 0,
    DMSAllowance2: 0,
    DMSAllowance3: 0,
    ticketPrice: 0,
    ticketPrice2: 0,
    ticketPrice3: 0,
    DMSBalance: 0,
    ownerTicketCount: 0,
    ownerTicketCount2: 0,
    ownerTicketCount3: 0,
    tokenName: "Unavailable",
    tokenName2: "Unavailable",
    tokenName3: "Unavailable",
  });
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [timeLeft2, setTimeLeft2] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [timeLeft3, setTimeLeft3] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [displayedticketPrice, setDisplayedticketPrice] = useState("");
  const [displayedticketPrice2, setDisplayedticketPrice2] = useState("");
  const [displayedticketPrice3, setDisplayedticketPrice3] = useState("");
  const [displayedTokenName, setDisplayedTokenName] = useState("");
  const [displayedTokenName2, setDisplayedTokenName2] = useState("");
  const [displayedTokenName3, setDisplayedTokenName3] = useState("");
  const [rewardTokenDistribute, setRewardTokenDistribute] = useState("");
  const [rewardTokenDistribute2, setRewardTokenDistribute2] = useState("");
  const [rewardTokenDistribute3, setRewardTokenDistribute3] = useState("");
  const [rewardTokenBurned, setRewardTokenBurned] = useState("");
  const [rewardTokenBurned2, setRewardTokenBurned2] = useState("");
  const [rewardTokenBurned3, setRewardTokenBurned3] = useState("");
  const [distributorBalance, setDistributorBalance] = useState("");
  const [distributorBalance2, setDistributorBalance2] = useState("");
  const [distributorBalance3, setDistributorBalance3] = useState("");
  const [ticketNumber, setTicketNumber] = useState("");
  const [ticketNumber2, setTicketNumber2] = useState("");
  const [ticketNumber3, setTicketNumber3] = useState("");
  const [winnerDetailsArray, setWinnerDetailsArray] = useState([]);
  const [winnerDetailsArray2, setWinnerDetailsArray2] = useState([]);
  const [winnerDetailsArray3, setWinnerDetailsArray3] = useState([]);
  const [DMSAllowance, setDMSAllowance] = useState(0);
  const [DMSAllowance2, setDMSAllowance2] = useState(0);
  const [DMSAllowance3, setDMSAllowance3] = useState(0);


  const calculateTimeLeft = (nextGameTime) => {
    const now = Math.floor(new Date().getTime() / 1000);
    const difference = nextGameTime - now;

    if (difference > 0) {
      const days = Math.floor(difference / (24 * 60 * 60));
      const hours = Math.floor((difference % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((difference % (60 * 60)) / 60);
      const seconds = difference % 60;

      setTimeLeft({ days, hours, minutes, seconds });
    } else {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  const calculateTimeLeft2 = (nextGameTime) => {
    const now = Math.floor(new Date().getTime() / 1000);
    const difference = nextGameTime - now;

    if (difference > 0) {
      const days = Math.floor(difference / (24 * 60 * 60));
      const hours = Math.floor((difference % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((difference % (60 * 60)) / 60);
      const seconds = difference % 60;

      setTimeLeft2({ days, hours, minutes, seconds });
    } else {
      setTimeLeft2({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  const calculateTimeLeft3 = (nextGameTime) => {
    const now = Math.floor(new Date().getTime() / 1000);
    const difference = nextGameTime - now;

    if (difference > 0) {
      const days = Math.floor(difference / (24 * 60 * 60));
      const hours = Math.floor((difference % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((difference % (60 * 60)) / 60);
      const seconds = difference % 60;

      setTimeLeft3({ days, hours, minutes, seconds });
    } else {
      setTimeLeft3({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };
  const connectButton = async () => {
    let chainID = await checkCorrectNetwork();
    if(chainID != 369){
      promptSwitchNetwork(369);
      return;
    }
    let wallet = await checkWalletAvailable();
    let address = await getUserAddress();
    let balance = await getMainBalance(); 
    let ticketPrice = await ticketPriceCheck();
    let ticketPrice2 = await ticketPriceCheck2();
    let ticketPrice3 = await ticketPriceCheck3();
    let DMSBalance = await checkDMSBalance(address);
    let ownerTicketCount = await ownerTicketCountCheck(address);
    let ownerTicketCount2 = await ownerTicketCountCheck2(address);
    let ownerTicketCount3 = await ownerTicketCountCheck3(address);
    let tokenName = await getTokenName();
    let tokenName2 = await getTokenName2();
    let tokenName3 = await getTokenName3();
    setData({
      wallet: wallet,
      chainId: chainID,
      address: address,
      balance: balance,
      ticketPrice: ticketPrice,
      ticketPrice2: ticketPrice2,
      ticketPrice3: ticketPrice3,
      DMSBalance: DMSBalance,
      ownerTicketCount: ownerTicketCount,
      ownerTicketCount2: ownerTicketCount2,
      ownerTicketCount3: ownerTicketCount3,
      tokenName: tokenName,
      tokenName2: tokenName2,
      tokenName3: tokenName3
    });
    setDMSAllowanceFn();
    setDMSAllowanceFn2();
    setDMSAllowanceFn3();
  };

  useEffect(() => {
    const fetchNextGameTime = async () => {
      const nextGameTime = await nextGameTimeCheck();
      calculateTimeLeft(nextGameTime);
    };

    fetchNextGameTime();

    const timerInterval = setInterval(() => {
      fetchNextGameTime();
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);
  useEffect(() => {
    const fetchNextGameTime2 = async () => {
      const nextGameTime2 = await nextGameTimeCheck2();
      calculateTimeLeft2(nextGameTime2);
    };

    fetchNextGameTime2();

    const timerInterval2 = setInterval(() => {
      fetchNextGameTime2();
    }, 1000);

    return () => clearInterval(timerInterval2);
  }, []); useEffect(() => {
    const fetchNextGameTime3 = async () => {
      const nextGameTime3 = await nextGameTimeCheck3();
      calculateTimeLeft3(nextGameTime3);
    };

    fetchNextGameTime3();

    const timerInterval3 = setInterval(() => {
      fetchNextGameTime3();
    }, 1000);

    return () => clearInterval(timerInterval3);
  }, []);

  const updateWalletInfo = async () => {
    const wallet = await checkWalletAvailable();
    if (wallet) {
      let address = await getUserAddress();
      let balance = await getMainBalance();
      let chainID = await checkCorrectNetwork();
      let ticketPrice = await ticketPriceCheck();
      let ticketPrice2 = await ticketPriceCheck2();
      let ticketPrice3 = await ticketPriceCheck3();
      let DMSBalance = await checkDMSBalance(address);
      let ownerTicketCount = await ownerTicketCountCheck(address);
      let ownerTicketCount2 = await ownerTicketCountCheck2(address);
      let ownerTicketCount3 = await ownerTicketCountCheck3(address);
      let tokenName = await getTokenName();
      let tokenName2 = await getTokenName2();
      let tokenName3 = await getTokenName3();
      setData({
        wallet: wallet,
        chainId: chainID,
        address: address,
        balance: balance,
        ticketPrice: ticketPrice,
        ticketPrice2: ticketPrice2,
        ticketPrice3: ticketPrice3,
        DMSBalance: DMSBalance,
        ownerTicketCount: ownerTicketCount,
        ownerTicketCount2: ownerTicketCount2,
        ownerTicketCount3: ownerTicketCount3,
        tokenName: tokenName,
        tokenName2: tokenName2,
        tokenName3: tokenName3,
      });
      setDMSAllowanceFn();
      setDMSAllowanceFn2();
      setDMSAllowanceFn3();
    } else {
      // setData(null);
    }
  };
  const [ticket1, setticket1] = useState(1);
  const [ticket2, setticket2] = useState(1);
  const [ticket3, setticket3] = useState(1);
  const setNumTickets = (input) => {
    let value = parseInt(input, 10);
    value = Math.min(Math.max(value, 1), 100);
    setticket1(parseInt(value));
  }
  const setNumTickets2 = (input) => {
    let value = parseInt(input, 10);
    value = Math.min(Math.max(value, 1), 100);
    setticket2(parseInt(value));
  }
  const setNumTickets3 = (input) => {
    let value = parseInt(input, 10);
    value = Math.min(Math.max(value, 1), 100);
    setticket3(parseInt(value));
  }
  const setDisplayedticketPriceFn = async () => {
    let f = await ticketPriceCheck();
    setDisplayedticketPrice(f);
  }
  const setDisplayedTokenNameFn = async () => {
    let f = await getTokenName();
    setDisplayedTokenName(f);
  }
  const setDisplayedticketPriceFn2 = async () => {
    let f = await ticketPriceCheck2();
    setDisplayedticketPrice2(f);
  }
  const setDisplayedTokenNameFn2 = async () => {
    let f = await getTokenName2();
    setDisplayedTokenName2(f);
  }
  const setDisplayedticketPriceFn3 = async () => {
    let f = await ticketPriceCheck3();
    setDisplayedticketPrice3(f);
  }
  const setDisplayedTokenNameFn3 = async () => {
    let f = await getTokenName3();
    setDisplayedTokenName3(f);
  }

  const setRewardTokenDistributeFn = async () => {
    let f = await checkRewardTokenDistribute();
    setRewardTokenDistribute(f);
  }
  const setRewardTokenDistribute2Fn = async () => {
    let f = await checkRewardTokenDistribute2();
    setRewardTokenDistribute2(f);
  }
  const setRewardTokenDistribute3Fn = async () => {
    let f = await checkRewardTokenDistribute3();
    setRewardTokenDistribute3(f);
  }
  const setRewardTokenBurnedFn = async () => {
    let f = await checkRewardTokenBurned();
    setRewardTokenBurned(f);
  }
  const setRewardTokenBurned2Fn = async () => {
    let f = await checkRewardTokenBurned2();
    setRewardTokenBurned2(f);
  }
  const setRewardTokenBurned3Fn = async () => {
    let f = await checkRewardTokenBurned3();
    setRewardTokenBurned3(f);
  }
  const setDistributorBalanceFn = async () => {
    let f = await getDistributorBalance();
    setDistributorBalance(f);
  }
  const setDistributorBalance2Fn = async () => {
    let f = await getDistributorBalance2();
    setDistributorBalance2(f);
  }
  const setDistributorBalance3Fn = async () => {
    let f = await getDistributorBalance3();
    setDistributorBalance3(f);
  }
  const ticketNumberCheckFn = async () => {
    let f = await ticketNumberCheck();
    setTicketNumber(f);
  }
  const ticketNumberCheck2Fn = async () => {
    let f = await ticketNumberCheck2();
    setTicketNumber2(f);
  }
  const ticketNumberCheck3Fn = async () => {
    let f = await ticketNumberCheck3();
    setTicketNumber3(f);
  }
  const setWinnerDetailsFn = async () => {
    const f = await oldWinnerDetails();
    setWinnerDetailsArray(f);
  }
  const setWinnerDetails2Fn = async () => {
    const f = await oldWinnerDetails2();
    setWinnerDetailsArray2(f);
  }
  const setWinnerDetails3Fn = async () => {
    const f = await oldWinnerDetails3();
    setWinnerDetailsArray3(f);
  }
  const setDMSAllowanceFn = async () => {
    if(data.wallet){
      const f = await checkDMSApproval(data.address);
      setDMSAllowance(f);
    }

  }
  const setDMSAllowanceFn2 = async () => {
    if(data.wallet){
    const f = await checkDMSApproval2(data.address);
    setDMSAllowance2(f);
    }
  }
  const setDMSAllowanceFn3 = async () => {
    if(data.wallet){
    const f = await checkDMSApproval3(data.address);
    setDMSAllowance3(f);
    }
  }

  const buyLotteryTickets = async () => {
    $('#processingModal').removeClass('d-none');
    let fromAdrress = data.address;
    try {
      //approve dms for the amount ticket1 * ticketPrice
      if(DMSAllowance < data.ticketPrice * ticket1){
        let approvalAmount = (data.ticketPrice * ticket1).toString();
        await approveDMS(approvalAmount, fromAdrress);
      }
      let res = await buyTickets(ticket1, fromAdrress);
      $('#processingModal').addClass('d-none');
      $("#successModal").removeClass("d-none");

    } catch (error) {
      $('#processingModal').addClass('d-none');
      $("#successModal").addClass("d-none");
      $("#errorModal").removeClass("d-none");
    }
    setDisplayedTokenNameFn();
    setDisplayedticketPriceFn();
    setDisplayedTokenNameFn2();
    setDisplayedticketPriceFn2();
    setDisplayedTokenNameFn3();
    setDisplayedticketPriceFn3();
    setRewardTokenBurnedFn();
    setRewardTokenBurned2Fn();
    setRewardTokenBurned3Fn();
    setRewardTokenDistributeFn();
    setRewardTokenDistribute2Fn();
    setRewardTokenDistribute3Fn();
    setDistributorBalanceFn();
    setDistributorBalance2Fn();
    setDistributorBalance3Fn()
    ticketNumberCheckFn();
    ticketNumberCheck2Fn();
    ticketNumberCheck3Fn();
    setWinnerDetailsFn();
    setWinnerDetails2Fn();
    setWinnerDetails3Fn();
    setDMSAllowanceFn();
    setDMSAllowanceFn2();
    setDMSAllowanceFn3();
  };

  const buyLotteryTickets2 = async () => {
    $('#processingModal').removeClass('d-none');
    let fromAdrress = data.address;
    try {
      //approve dms for the amount ticket1 * ticketPrice
      if(DMSAllowance2 < data.ticketPrice2 * ticket2){
        let approvalAmount = (data.ticketPrice2 * ticket2).toString();
       await approveDMS2(approvalAmount, fromAdrress);
      }
      let res = await buyTickets2(ticket2, fromAdrress);
      $('#processingModal').addClass('d-none');
      $("#successModal").removeClass("d-none");

    } catch (error) {
      $('#processingModal').addClass('d-none');
      $("#successModal").addClass("d-none");
      $("#errorModal").removeClass("d-none");
    }
    setDisplayedTokenNameFn();
    setDisplayedticketPriceFn();
    setDisplayedTokenNameFn2();
    setDisplayedticketPriceFn2();
    setDisplayedTokenNameFn3();
    setDisplayedticketPriceFn3();
    setRewardTokenBurnedFn();
    setRewardTokenBurned2Fn();
    setRewardTokenBurned3Fn();
    setRewardTokenDistributeFn();
    setRewardTokenDistribute2Fn();
    setRewardTokenDistribute3Fn();
    setDistributorBalanceFn();
    setDistributorBalance2Fn();
    setDistributorBalance3Fn();
    ticketNumberCheckFn();
    ticketNumberCheck2Fn();
    ticketNumberCheck3Fn();
    setWinnerDetailsFn();
    setWinnerDetails2Fn();
    setWinnerDetails3Fn();
    setDMSAllowanceFn();
    setDMSAllowanceFn2();
    setDMSAllowanceFn3();
  };
  const buyLotteryTickets3 = async () => {
    $('#processingModal').removeClass('d-none');
    let fromAdrress = data.address;
    try {
      //approve dms for the amount ticket1 * ticketPrice
      if(DMSAllowance3 < data.ticketPrice3 * ticket3){
        let approvalAmount = (data.ticketPrice3 * ticket3).toString();
        await approveDMS3(approvalAmount, fromAdrress);
      }
      
      let res = await buyTickets3(ticket3, fromAdrress);
      $('#processingModal').addClass('d-none');
      $("#successModal").removeClass("d-none");

    } catch (error) {
      $('#processingModal').addClass('d-none');
      $("#successModal").addClass("d-none");
      $("#errorModal").removeClass("d-none");
    }
    setDisplayedTokenNameFn();
    setDisplayedticketPriceFn();
    setDisplayedTokenNameFn2();
    setDisplayedticketPriceFn2();
    setDisplayedTokenNameFn3();
    setDisplayedticketPriceFn3();
    setRewardTokenBurnedFn();
    setRewardTokenBurned2Fn();
    setRewardTokenBurned3Fn();
    setRewardTokenDistributeFn();
    setRewardTokenDistribute2Fn();
    setRewardTokenDistribute3Fn();
    setDistributorBalanceFn();
    setDistributorBalance2Fn();
    setDistributorBalance3Fn();
    ticketNumberCheckFn();
    ticketNumberCheck2Fn();
    ticketNumberCheck3Fn();
    setWinnerDetailsFn();
    setWinnerDetails2Fn();
    setWinnerDetails3Fn();
    setDMSAllowanceFn();
    setDMSAllowanceFn2();
    setDMSAllowanceFn3();
  };
  useEffect(() => {
    setDisplayedTokenNameFn();
    setDisplayedticketPriceFn();
    setDisplayedTokenNameFn2();
    setDisplayedticketPriceFn2();
    setDisplayedTokenNameFn3();
    setDisplayedticketPriceFn3();
    setRewardTokenBurnedFn();
    setRewardTokenBurned2Fn();
    setRewardTokenBurned3Fn();
    setRewardTokenDistributeFn();
    setRewardTokenDistribute2Fn();
    setRewardTokenDistribute3Fn();
    setDistributorBalanceFn();
    setDistributorBalance2Fn();
    setDistributorBalance3Fn();
    ticketNumberCheckFn();
    ticketNumberCheck2Fn();
    ticketNumberCheck3Fn();
    setWinnerDetailsFn();
    setWinnerDetails2Fn();
    setWinnerDetails3Fn();
    setDMSAllowanceFn();
    setDMSAllowanceFn2();
    setDMSAllowanceFn3();

    const handleAccountsChanged = async (accounts) => {

      if (accounts.length > 0) {
        updateWalletInfo();
        console.log("accounts changed" + data.address);
      } else {
        setData({
          wallet: false,
          chainId: "Unavailable",
          address: "Unavailable",
          balance: 0,
          ticketPrice: 0,
          ticketPrice2: 0,
          ticketPrice3: 0,
          DMSBalance: 0,
          ownerTicketCount: 0,
          ownerTicketCount2: 0,
          ownerTicketCount3: 0,
          tokenName: "Unavailable",
          tokenName2: "Unavailable",
          tokenName3: "Unavailable"
        });
      }
    };

    window.ethereum.on("accountsChanged", handleAccountsChanged);

    return () => {
      window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
    };
  }, []);

  return (
    <div className="main-outer-container">
      <div className="overlay-for-background"></div>
      <div className="container-fluid main-container">
        <div className="container-fluid px-5">
          <div className="navigation-box">

            <div className="row justify-content-between align-items-center">
              <div><a href="https://daimillions.win/" target="_blank">
                <img className="dms-logo-img" src="images/favicon.png" />
              </a>
              </div>
              <div className="lt-nav-links d-none d-xl-block">
                <a href="https://t.me/daireflections" target="_blank">Community</a>
              </div>
              <div className="lt-nav-links d-none d-xl-block">
                <a target="_blank" href="https://www.dextools.io/app/en/pulse/pair-explorer/0xc5210ab178d072d8672f273cb862b4379f210d4a">Dextools</a>
              </div>
              <div className="lt-nav-links d-none d-xl-block">
                <a target="_blank" href="https://dexscreener.com/pulsechain/0xc5210ab178d072d8672f273cb862b4379f210d4a">DexScreener</a>
              </div>
              <div className="lt-nav-links d-none d-xl-block">
                <a target="_blank" href="https://bridge.mypinata.cloud/ipfs/bafybeiagreiatsnjvoriublqmv3bz4atiu33pecffdiyzponjbvzf3klwm/?#/bridge">ETH Bridge</a>
              </div>
              <div className="button-container">
                <a target="_blank" href="https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/?#/?outputCurrency=0x6f47d916440428bC10322b977291Cf16E0405486" className="buttonMain mr-3">
                  BUY $DMS
                </a>
                {data.wallet ? (
                  <button className="buttonMain">
                    {truncateAddress(data.address, 6, 4)}
                  </button>
                ) : (
                  <button onClick={connectButton} className="buttonMain">
                    <i class="fa-solid fa-wallet"></i> CONNECT
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container text-center">
            <h2 className="main-title">DMS Lottery</h2>
            <div className="d-flex lt-information justify-content-between align-items-center">
              {/* <p>Price: {displayedticketPrice.slice(0, -9) } DMS / Ticket</p> */}
              {
                data.wallet && BigInt(data.DMSBalance) > 0 ? (
                  <p className="dms-balance">DMS Balance: {data.DMSBalance.slice(0, -9)}</p>
                ) : (<p className="dms-balance">DMS Balance: 0</p>)
              }

            </div>

            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4">
                <div className="lottery-box">
                  <p className="winning-token-p">Winning Token: <span>{displayedTokenName}</span></p>
                  <input
                    type="number"
                    placeholder="0"
                    value={ticket1}
                    className="buy-input"
                    onChange={(e) => { setNumTickets(e.target.value) }}
                  />
                  {data.wallet ? (
                    <button className="buttonBuy" onClick={buyLotteryTickets}>
                      <i class="fa-solid fa-wallet"></i> BUY TICKETS
                    </button>
                  ) : (
                    <button className="buttonBuy" onClick={connectButton}>
                      <i class="fa-solid fa-wallet"></i> CONNECT
                    </button>
                  )}
                  <div className="ticket-price-info">
                    {(displayedticketPrice.slice(0, -9) * ticket1).toLocaleString()} DMS + Gas Required
                  </div>
                  <div>
                    <p className="lottery-timer"><span>{timeLeft.days}D</span>
                      <span>{timeLeft.hours}H</span>
                      <span>{timeLeft.minutes}M</span>
                      <span>{timeLeft.seconds}S</span></p>
                  </div>
                  <div className="lt-info">
                    <p className="burned"><span>{displayedTokenName} To Be Burned:</span> {(distributorBalance / 2).toLocaleString()}</p>
                    <p className="tickets-sold"><span>Tickets Sold:</span> {ticketNumber}</p>
                    <p className="tickets-sold"><span>You Own:</span> {data.ownerTicketCount} Tickets</p>
                    <p className="current-pooled"><span>{displayedTokenName} Prize Pot:</span> <br />{(distributorBalance / 2).toLocaleString()}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="lottery-box">
                  <p className="winning-token-p">Winning Token: <span>{displayedTokenName2}</span></p>
                  <input
                    type="number"
                    placeholder="0"
                    value={ticket2}
                    className="buy-input"
                    onChange={(e) => { setNumTickets2(e.target.value) }}
                  />
                  {data.wallet ? (
                    <button className="buttonBuy" onClick={buyLotteryTickets2}>
                      <i class="fa-solid fa-wallet"></i> BUY TICKETS
                    </button>
                  ) : (
                    <button className="buttonBuy" onClick={connectButton}>
                      <i class="fa-solid fa-wallet"></i> CONNECT
                    </button>
                  )}
                  <div className="ticket-price-info">
                    {(displayedticketPrice2.slice(0, -9) * ticket2).toLocaleString()} DMS + Gas Required
                  </div>
                  <div>
                    <p className="lottery-timer"><span>{timeLeft2.days}D</span>
                      <span>{timeLeft2.hours}H</span>
                      <span>{timeLeft2.minutes}M</span>
                      <span>{timeLeft2.seconds}S</span></p>
                  </div>
                  <div className="lt-info">
                    <p className="burned"><span >{displayedTokenName2} To Be Burned:</span> {(distributorBalance2 / 2).toLocaleString()}</p>
                    <p className="tickets-sold"><span>Tickets Sold:</span> {ticketNumber2}</p>
                    <p className="tickets-sold"><span>You Own:</span> {data.ownerTicketCount2} Tickets</p>
                    <p className="current-pooled"><span>{displayedTokenName2} Prize Pot:</span> <br />{(distributorBalance2 / 2).toLocaleString()}</p>
                  </div>
                </div>
              </div> <div className="col-lg-4">
                <div className="lottery-box">
                  <p className="winning-token-p">Winning Token: <span>{displayedTokenName3}</span></p>
                  <input
                    type="number"
                    placeholder="0"
                    value={ticket3}
                    className="buy-input"
                    onChange={(e) => { setNumTickets3(e.target.value) }}
                  />
                  {data.wallet ? (
                    <button className="buttonBuy" onClick={buyLotteryTickets3}>
                      <i class="fa-solid fa-wallet"></i> BUY TICKETS
                    </button>
                  ) : (
                    <button className="buttonBuy" onClick={connectButton}>
                      <i class="fa-solid fa-wallet"></i> CONNECT
                    </button>
                  )}
                  <div className="ticket-price-info">
                    {(displayedticketPrice3.slice(0, -9) * ticket3).toLocaleString()} DMS + Gas Required
                  </div>
                  <div>
                    <p className="lottery-timer"><span>{timeLeft3.days}D</span>
                      <span>{timeLeft3.hours}H</span>
                      <span>{timeLeft3.minutes}M</span>
                      <span>{timeLeft3.seconds}S</span></p>
                  </div>
                  <div className="lt-info">
                    <p className="burned"><span>{displayedTokenName3} To Be Burned:</span> {(distributorBalance3 / 2).toLocaleString()}</p>
                    <p className="tickets-sold"><span>Tickets Sold:</span> {ticketNumber3}</p>
                    <p className="tickets-sold"><span>You Own:</span> {data.ownerTicketCount3} Tickets</p>
                    <p className="current-pooled"><span>{displayedTokenName3} Prize Pot:</span> <br />{(distributorBalance3 / 2).toLocaleString()}</p>
                  </div>
                </div>
              </div>

            </div>

          </div>
          {winnerDetailsArray.length > 0  || winnerDetailsArray2.length > 0 || winnerDetailsArray3.length > 0 ? (
            <div className="winners-outer-container">
              <div className="row justify-content-center align-items-center">
                <div className="winners-container col-lg-3">
                  <p className="wc-p">Winners Lottery 1</p>
                  {winnerDetailsArray.map((winner, index) => (
                    <div className="winner-box">
                      <img className="winner-badge" src="images/reward.png" />
                      <div className="winner-info">
                        <p className="winner-token">Won: {winner.rewardTokenName}</p>
                        <p className="winner-amount">Amount: {winner.winnerRewardAmount}</p>
                        <p className="winner-amount">{winner.rewardTokenName} Burned: {winner.winnerRewardAmount}</p>
                        <p className="winner-address">Address: {winner.winnerAddress}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="winners-container col-lg-3">
                  <p className="wc-p">Winners Lottery 2</p>
                  {winnerDetailsArray2.map((winner, index) => (
                    <div className="winner-box">
                      <img className="winner-badge" src="images/reward.png" />
                      <div className="winner-info">
                        <p className="winner-token">Won: {winner.rewardTokenName}</p>
                        <p className="winner-amount">Amount: {winner.winnerRewardAmount}</p>
                        <p className="winner-amount">{winner.rewardTokenName} Burned: {winner.winnerRewardAmount}</p>
                        <p className="winner-address">Address: {winner.winnerAddress}</p>
                      </div>
                    </div>
                  ))}
                  <div class="winner-box"><img class="winner-badge" src="images/reward.png"/><div class="winner-info"><p class="winner-token">Won: TRUMP</p><p class="winner-amount">Amount: 14510999</p><p class="winner-amount">TRUMP Burned: 14510999</p><p class="winner-address">Address: 0xd8025919d61898Ecf920643C8f773c93B2839753</p></div></div>
                  <div class="winner-box"><img class="winner-badge" src="images/reward.png"/><div class="winner-info"><p class="winner-token">Won: TEDDY BEAR ㉾</p><p class="winner-amount">Amount: 109765094</p><p class="winner-amount">TEDDY BEAR ㉾ Burned: 109765094</p><p class="winner-address">Address: 0x7094072f97d04c0784fFB95677c52c605254152D</p></div></div>
                  <div class="winner-box"><img class="winner-badge" src="images/reward.png"/><div class="winner-info"><p class="winner-token">Won: CTT</p><p class="winner-amount">Amount: 27088</p><p class="winner-amount">CTT Burned: 27088</p><p class="winner-address">Address: 0x078cbA355B90f272586e1891Fe10640F62210021</p></div></div>
                </div>
                <div className="winners-container col-lg-3">
                  <p className="wc-p">Winners Lottery 3</p>
                  {winnerDetailsArray3.map((winner, index) => (
                    <div className="winner-box">
                      <img className="winner-badge" src="images/reward.png" />
                      <div className="winner-info">
                        <p className="winner-token">Won: {winner.rewardTokenName}</p>
                        <p className="winner-amount">Amount: {winner.winnerRewardAmount}</p>
                        <p className="winner-amount">{winner.rewardTokenName} Burned: {winner.winnerRewardAmount}</p>
                        <p className="winner-address">Address: {winner.winnerAddress}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>) : (<div></div>)}
        </div>

        <div id="processingModal" className="d-none" >
          <div class="modal-custom d-flex justify-content-center align-items-center flex-column">
            <button id="processing-close" className="close-btn" onClick={() => {
              $('#processingModal').addClass('d-none');
            }}>x</button>
            <p className="pt-3 text-center"><span id="processing-text">Processing</span><br /><span className="f-16">(Approve All Transactions)</span></p>
            <div class="host">
              <div class="loading loading-0"></div>
              <div class="loading loading-1"></div>
              <div class="loading loading-2"></div>
            </div>


          </div> <div className="mc-overlay"></div>
        </div>


        <div id="successModal" className="d-none">
          <div class="modal-custom d-flex justify-content-center align-items-center flex-column">
            <button id="success-close" className="close-btn" onClick={() => {
              $('#successModal').addClass('d-none');
            }}>x</button>
            <p>Success</p>
            <img src="images/tick.png" />

          </div>
          <div className="mc-overlay"></div>

        </div>


        <div id="errorModal" className="d-none">
          <div class="modal-custom d-flex justify-content-center align-items-center flex-column">
            <button id="error-close" className="close-btn" onClick={() => {
              $('#errorModal').addClass('d-none');
            }}>x</button>

            <p>Error: Transaction Failure</p>
            <img src="images/cross.png" /></div>
          <div className="mc-overlay"></div>

        </div>



      </div></div>
  );
}

export default App;
